import { useEffect, useState } from 'react';
//components
import Slider from '@material-ui/core/Slider';
import CheckedIcon from 'media/svgs/check-mark.svg?react';
import IosSwitchLarge from './IosSwitchLarge';
//styling
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  // Dialog,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';
import animationData from 'media/jsons/spinner.json';
import { FiX } from 'react-icons/fi';
import Lottie from 'react-lottie';
import { NotificationManager } from 'react-notifications';
// utils
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'ui/components';
import { tableData } from 'utils/planTable';

const makeClasses = makeStyles((theme) => ({
  dialogClass: {
    boxShadow: `rgba(0, 0, 0, 0.56) 0px 22px 70px 4px`,
    height: '100vh',
    width: '100vw',
    overflowX: 'hidden',
    zIndex: 100000,
    margin: 0,
    borderRadius: 0,
    '@media (max-width: 768px)': {
      boxShadow: `rgba(0, 0, 0, 0.56) 0px 22px 70px 4px`,
      background: '#fff',
      overflowX: 'hidden',
    },
  },
  scrollPaper: {
    height: 'auto',
  },
  mainDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    fontFamily: theme.text.normalText.fontFamily,
  },

  firstDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: '2VW',
    paddingRight: '2vw',
    width: '100%',
    height: '60vh',
    backgroundImage:
      'url(https://atarim.io/wp-content/uploads/shape-background-pricing.png),linear-gradient(212deg, #E72D67 0%, #6D5DF3 89%)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  dragSliderInfo: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
  },
  statusMenuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    fontSize: '1vw',
    marginTop: '16px',
    '&::after': {
      content: (props) => (props.yearlyPlan ? "'2 months free'" : "''"),
      position: 'absolute',
      fontSize: '16px',
      fontWeight: 'bold',
      marginLeft: '412px',
      animation: (props) => (props.yearlyPlan ? 'fadeIn 1.3s ease-in-out' : ''),
      color: '#fff',
      '@media (max-width: 1199px)': {
        marginTop: '-83px',
        marginLeft: '250px',
      },
    },
  },
  startNowButton: {
    color: '#fff',
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: '16px',
    backgroundColor: '#6D5DF3',
    borderRadius: 30,
    border: 'none',
    maxHeight: 36,
    minHeight: '46px',
    width: '100%',
    margin: '24px 0',
    padding: '15px 30px',
  },
  greyText: {
    fontSize: '16px',
    color: '#363D4D',
  },
  cardUpper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  currentPlan: {
    color: '#6D5DF3',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  closeIconDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '95%',
    minHeight: '40px',
  },
  closeIcon: {
    fontSize: 20,
    cursor: 'pointer',
    color: '#ffffff',
    height: '100',
    float: 'right',
  },
  topText: {
    width: '74%',
    color: '#fff',
    fontFamily: theme.text.normalText.fontFamily,
    marginTop: '0px',
    textAlign: 'center',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
  stagesLoader: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardInfo: {
    display: 'flex',
    gap: '10px',
    marginTop: '10px',
    color: '#363D4D',
    fontSize: '16px',
  },
  cardName: {
    margin: 0,
    color: '#363D4D',
  },
  seatsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '20px',
    flexWrap: 'wrap',
  },
  moreSeats: {
    width: '25%',
    padding: '25px',
    position: 'relative',
    color: '#363D4D',
    borderRadius: '10px !important',
    animation: `$slideAnimateDown 0.2s ease-out forwards`,
    background: '#fff',
    boxShadow: '0px 0px 49.2353px rgba(0, 0, 0, 0.16)',
    overflow: 'hidden',
    fontFamily: theme.text.normalText.fontFamily,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      width: '100%',
      left: 0,
      height: '5px',
      backgroundColor: '#6D5DF3',
      borderRadius: '25px 25px 0px 0px',
    },
  },
  moreSeatsAnimateUp: {
    width: '25%',
    padding: '25px',
    color: '#363D4D',
    borderRadius: '10px',
    animation: `$slideAnimateUp 0.2s ease-out forwards`,
    background: '#fff',
    boxShadow: '0px 0px 49.2353px rgba(0, 0, 0, 0.16)',
    '&::before': {
      content: '',
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '5px',
      backgroundColor: '#6D5DF3',
    },
  },
  '@keyframes slideAnimateDown': {
    '0%': {
      opacity: 0,
      borderRadius: '0px',
      height: '0px',
    },
    '33%': {
      opacity: 0,
      borderRadius: '5px',
      height: '77px',
    },
    '66%': {
      opacity: 0,
      borderRadius: '5px',
      height: '154px',
    },
    '100%': {
      opacity: 1,
      borderRadius: '10px',
      height: '194px',
    },
  },
  '@keyframes slideAnimateUp': {
    '0%': {
      opacity: 1,
      borderRadius: '10px',
      height: '194px',
    },
    '25%': {
      opacity: 0,
      borderRadius: '5px',
      height: '174px',
    },
    '50%': {
      opacity: 0,
      borderRadius: '5px',
      height: '116px',
    },
    '75%': {
      opacity: 0,
      borderRadius: '5px',
      height: '58px',
    },
    '100%': {
      opacity: 0,
      borderRadius: '0px',
      height: '0px',
      padding: '0px 0px',
      display: 'none',
    },
  },
  sliderSwitch: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    paddingTop: '10px',
  },
  bookCallcontainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  moreSeatsdesc: {
    marginBottom: '15px',
    fontSize: '16px',
  },
  bookCall: {
    color: '#fff',
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: '16px',
    backgroundColor: '#6D5DF3',
    borderRadius: '5px !important',
    border: 'none',
    maxHeight: 36,
    minHeight: '46px',
    width: '100%',
    marginBottom: '12px',
    padding: '15px 30px',
    '&:hover': {
      backgroundColor: '#6D5DF3',
    },
  },
  popoverDiv: {
    padding: '10px',
    marginTop: '-30px',
    marginLeft: '-50px',
    fontFamily: theme.text.normalText.fontFamily,
    position: 'absolute',
    background: '#fcfcfc',
    boxShadow:
      '#33333333 0px 5px 5px -3px, #24242423 0px 8px 10px 1px, #1E1E1E1F 0px 3px 14px 2px',
    zIndex: 100,
    cursor: 'pointer',
    borderRadius: '8px',
    animation: `$popoverAnimate 0.2s ease-out forwards`,
  },
  '@keyframes popoverAnimate': {
    '0%': {
      opacity: 0,
      padding: '8px',
    },
    '25%': {
      opacity: 0.5,
      padding: '8px',
    },
    '50%': {
      opacity: 1,
      padding: '10px',
    },
    '100%': {
      opacity: 1,
      padding: '10px',
    },
  },
  cardDescription: {
    minHeight: '63px',
    marginTop: '-10px',
    color: '#363D4D',
    fontSize: '16px',
  },
  perSeat: {
    color: '#A4ABC5',
    fontSize: '16px',
    margin: '8px 0px',
  },
  basePrice: {
    color: '#363D4D',
    fontSize: '16px',
    margin: '8px 0px',
  },
  priceUpdatedInfo: {
    padding: '10px',
    fontFamily: theme.text.normalText.fontFamily,
    background: '#fcfcfc',
    boxShadow:
      'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px',
    zIndex: 100,
    cursor: 'pointer',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginTop: '15px',
  },
  closeIconPopup: {
    fontSize: 20,
    cursor: 'pointer',
    color: '#000',
  },
  heading: {
    fontSize: '59px',
    fontWeight: 700,
    color: '#fff',
    marginTop: '0px',
    lineHeight: 1.3,
    marginBottom: '40px',
  },
  dialogTitle: {
    width: '100%',
    maxWidth: '700px',
    marginBottom: '16px',
    fontSize: '18px',
    marginTop: 0,
  },
  botLine: {
    height: '1px',
    backgroundColor: '#cccccc',
    border: 'none',
  },
  customTooltip: {
    marginTop: '-76px',
    position: 'absolute',
    backgroundColor: '#000',
    padding: '5px 8px',
    borderRadius: '5px',
    fontSize: '12px',
    letterSpacing: '.5px',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    marginLeft: '-4px',
    userSelect: 'none',
    pointerEvents: 'none',
  },
  tooltipArrow: {
    color: '#000',
    position: 'absolute',
    marginTop: '-50px',
    marginLeft: '-4px',
    userSelect: 'none',
    pointerEvents: 'none',
  },

  headTd: {
    fontFamily: theme.text.normalText.fontFamily,
    fontWeight: 700,
    padding: '15px 15px 15px 15px',
    verticalAlign: 'middle',
    backgroundColor: '#FFFFFF',
    width: '14%',
  },
  headTdMain: {
    fontFamily: theme.text.normalText.fontFamily,
    fontWeight: 700,
    padding: '15px 15px 15px 15px',
    verticalAlign: 'middle',
    backgroundColor: '#FFFFFF',
    width: '30%',
  },
  dataRow: {
    background: '#ECF3F9',
  },
  dataItem: {
    verticalAlign: 'middle',
    padding: '10px 15px 10px 15px',
  },
  dataContent: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '1.3em',
    fontFamily: theme.text.normalText.fontFamily,
    '& svg': {
      strokeWidth: 1,
    },
  },

  highlightPlan: {
    background: 'linear-gradient(90.58deg, #E72D67 13.97%, #6D5DF3 84.16%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },

  tableHead: {
    textAlign: 'left',
    position: 'sticky',
    top: 0,
  },
  planEverything: {
    textTransform: 'capitalize',
  },
  copyWrapper: {
    minHeight: 230,
  },
  featureAvailable: {
    color: '#6D5DF3',
    fontWeight: 'bold',
    fontSize: '14px',
  },
}));

const PrettoSlider = withStyles({
  root: {
    marginTop: '1vh',
    color: '#FFFFFF',
    height: 5,
    width: '40vw',
    minWidth: '280px',
  },
  thumb: {
    height: 35,
    border: 'none',
    width: 35,
    marginTop: -10,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
    boxShadow: 'none !important;',
    backgroundColor: '#6D5DF3',
    cursor: 'grab',
    borderRadius: '20% 50% 50% 50%',
  },
  track: {
    height: 10,
    borderRadius: 10,
    color: '#FFFFFF',
  },
  rail: {
    height: 10,
    borderRadius: 10,
  },
})(Slider);

const PlanUpgrading = (props) => {
  const {
    title,
    description,
    isChangingInterval,
    setIsChangingInterval,
    redirectTo,
    featureAvailable,
  } = props;

  const dispatch = useDispatch();
  const {
    pricingPlans,
    upgradePlansLoading,
    isPlansDialogOpened,
    userData: user,
    hostedPagedata,
    startingCheckout,
    plansSuccessMessage,
    plansErrorMessage,
    planSearch,
  } = useSelector((state) => state.user);
  const [yearlyPlan, setYearlyPlane] = useState(false);
  const [persons, setPersons] = useState(user?.plan?.users);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPriceUpdated, setIsPriceUpdated] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState('');
  const [selectedPlanData, setSelectedPlanData] = useState('');
  const [isMoreSeatsVisible, setIsmoreSeatsVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [enterpriseFeature, setEnterpriseFeature] = useState({});
  const [coupon, setCoupon] = useState('');

  const [defaultTitle] = useState('Upgrade Your Plan');
  const [featureTitle] = useState(['free', 'starter', 'Pro']);
  const [defaultDescription] = useState(
    'Take control of your workflow with Atarim and stop wasting your time talking to your clients and get to the actual work by collaborating with them instead.'
  );

  const yearlyUsersLimit = pricingPlans.map((p) => p.max_users_yearly);
  const MonthlyUsersLimit = pricingPlans.map((p) => p.max_users_monthly);
  const maxYearlyUsersLimit = Math.max(...yearlyUsersLimit);
  const maxMonthlyUsersLimit = Math.max(...MonthlyUsersLimit);
  const maxSliderValue = yearlyPlan
    ? maxYearlyUsersLimit
    : maxMonthlyUsersLimit;
  // no of added users the user has
  const addedUsers = user.plan?.users - user.limit?.users;

  const classes = makeClasses({ yearlyPlan });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const CustomThumb = (props) => {
    const { children, ...otherProps } = props;
    const value = otherProps['aria-valuenow'];
    const toolTipvalue = persons > maxSliderValue ? value + '+' : value + 1;
    return (
      <span {...otherProps}>
        {/* custom tooltip to avoid rendering issue */}
        <span className={classes.customTooltip}>
          {toolTipvalue + (value > 0 ? ' seats' : ' seat')}
        </span>
        <span className={classes.tooltipArrow}>▼</span>
        <FontAwesomeIcon
          style={{ marginTop: '-2px', marginLeft: '2px' }}
          icon={faUserPlus}
        />
      </span>
    );
  };

  // we have to call the API on the button on which the dialog opens
  useEffect(() => {
    dispatch({ type: 'UPGRADE_PLANS' });
    // upgradePlans();
  }, []);

  // initiating chargebee
  let cbInstance = window.Chargebee.init({
    site: user?.payment_site ? user?.payment_site : 'atarimbilling',
  });

  const openCheckoutFunc = (card) => {
    setSelectedPlanId(card.id);
    const planId = card.plans[yearlyPlan ? 0 : 1]?.plan_id;
    const rawId = card.plans[yearlyPlan ? 0 : 1]?.id;
    const amount = calculatePrice(card);
    const data = {
      plan_id: planId,
      rawId: rawId,
      amount: amount,
      users: persons,
      rank: card.rank,
      cycle: yearlyPlan ? 'Yearly' : 'Monthly',
      coupon: coupon,
    };

    setSelectedPlanData(data);
    // fetching the hosted page data
    dispatch({ type: 'OPEN_CHECKOUT', params: data });
    setCoupon('');
  };

  const navigate = useNavigate();

  const gotoPrevPage = () => {
    // if updateIndex is there || from dashboard page
    if (redirectTo !== undefined && redirectTo !== '/dashboard') {
      const redirectUrl = `/${redirectTo}`;
      // (fromPageIndex == 10) is for dashboard on which we currently are so no need
      navigate(redirectUrl);
    }
  };

  useEffect(() => {
    // initiate the chargebee modal once the response is there in state
    if (Object.keys(hostedPagedata).length > 0) {
      let isCheckoutSuccessful = false;
      cbInstance.openCheckout({
        hostedPage: async function () {
          return hostedPagedata;
        },
        close: function () {
          // removeHostedPageData();
          dispatch({ type: 'REMOVE_HOSTED_PAGE_DATA' });
          // closing the dialogs if the checkout is done
          if (isCheckoutSuccessful) {
            dispatch({ type: 'HANDLE_PLANS_DIALOG', params: false });
            // to the page from where came for plan updation
            gotoPrevPage();
          }
        },
        success: function (hostedPageId) {
          // update the variable so to close the dialogs
          isCheckoutSuccessful = true;
          window.dataLayer.push({
            event: 'Checkout_Complete',
          });
          dispatch({
            type: 'SUBSCRIPTION_DETAILS',
            id: hostedPageId,
            rawId: selectedPlanData?.rawId,
            users: selectedPlanData?.users,
          });

          dispatch({ type: 'REMOVE_HOSTED_PAGE_DATA' });
          //Send confirmation to extension but issue is that extension closes the tab
          //Need to make some changes to the extension side before uncommenting this
          // sendMessageToExtension({
          //     type: 'planUpgrade'
          // });
        },
        step: function (value) {
          // value -> which step in checkout
        },
      });
    }
  }, [hostedPagedata]);

  const sliderChange = (e, newValue) => {
    if (!isAnimating) {
      setPersons(newValue + 1);
      // for anomating the more seats container
      if (newValue === maxSliderValue) {
        setIsmoreSeatsVisible(true);
      } else {
        if (isMoreSeatsVisible) {
          setIsAnimating(true);
          setTimeout(() => {
            setIsAnimating(false);
            setIsmoreSeatsVisible(false);
          }, 200);
        }
      }
    }
  };

  // The plan on which the user already is
  const currentPlan = pricingPlans.filter(
    (p) => p.rank === user?.plan?.rank
  )[0];

  useEffect(() => {
    // check if the user has yearly plan or monthly plan
    if (user?.plan?.cycle === 'Yearly') {
      setYearlyPlane(true);
    } else {
      setYearlyPlane(false);
    }
  }, []);

  const calculatePrice = (card) => {
    // calculating the price of each plan
    const maxUsers = yearlyPlan
      ? card.max_users_yearly
      : card.max_users_monthly;
    const basePrice = yearlyPlan
      ? card.base_price_yearly
      : card.base_price_monthly;
    const userCostYearly =
      (persons <= maxUsers ? persons - 1 : maxUsers - 1) *
      card.plans[0]?.extra_seat_info_yearly;
    const userCostMonthly =
      (persons <= maxUsers ? persons - 1 : maxUsers - 1) *
      card.plans[1]?.extra_seat_info_monthly;
    const totalCost =
      (yearlyPlan ? userCostYearly : userCostMonthly) + Math.round(basePrice);
    return totalCost;
  };

  const disabledButton = (card) => {
    //  this is the conditionnal check that return either true or false
    return (
      (yearlyPlan &&
        user?.plan?.cycle === 'Yearly' &&
        persons === user?.plan?.users &&
        user.subscription &&
        currentPlan?.rank === card?.rank) ||
      (!yearlyPlan &&
        user?.plan?.cycle === 'Monthly' &&
        persons === user?.plan?.users &&
        user.subscription &&
        currentPlan?.rank === card?.rank) ||
      (yearlyPlan && persons > card.max_users_yearly) ||
      (!yearlyPlan && persons > card.max_users_monthly) ||
      addedUsers > persons
    );
  };

  const getButtonText = (card) => {
    if (persons === user?.plan?.users && currentPlan?.rank === card?.rank) {
      if (
        (yearlyPlan && user?.plan?.cycle === 'Yearly') ||
        (!yearlyPlan && user?.plan?.cycle === 'Monthly')
      ) {
        if (!user.subscription) {
          if (startingCheckout && card.id === selectedPlanId) {
            return 'Starting...';
          } else {
            return 'Renew Subscription';
          }
        } else {
          return 'Already On Plan';
        }
      }
    }
    if (startingCheckout && card.id === selectedPlanId) {
      return 'Starting...';
    }
    return 'Start Now';
  };

  useEffect(() => {
    // if the price is updated then inform the user about
    pricingPlans.map((card) => {
      if (persons === user?.plan?.users && currentPlan?.rank === card.rank) {
        if (
          (yearlyPlan && user?.plan?.cycle === 'Yearly') ||
          (!yearlyPlan && user?.plan?.cycle === 'Monthly')
        ) {
          // notifiy user that price is changed
          if (yearlyPlan) {
            if (
              Number(card.plans[0].selling_price) !==
              Number(card.max_users_yearly) * (card.plans[0].user_limit - 1) +
                Number(card.base_price_yearly)
            ) {
              !isPriceUpdated && setIsPriceUpdated(true);
            }
          } else {
            if (
              Number(card.plans[1].selling_price) !==
              Number(card.max_users_monthly) * (card.plans[1].user_limit - 1) +
                Number(card.base_price_monthly)
            ) {
              !isPriceUpdated && setIsPriceUpdated(true);
            }
          }
        }
      }
    });
    pricingPlans.map((each) =>
      each.name === 'Enterprise' ? setEnterpriseFeature(each) : each
    );
  }, [pricingPlans]);

  // displaying the popover
  const handleOpen = (id) => {
    setAnchorEl(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const switchChange = (e) => {
    setYearlyPlane(e.target.checked);
  };

  useEffect(() => {
    // if the dialog opens up to change interval
    // we want to auto set the inverse of cycle of what user is already on
    if (isChangingInterval) {
      // check if the user has yearly plan or monthly plan and set it inversely
      if (user?.plan?.cycle === 'Yearly') {
        setYearlyPlane(false);
      } else {
        setYearlyPlane(true);
      }
    }
  }, []);

  const closePlansDialog = () => {
    if (isChangingInterval) {
      // user Opened dialog from some where to change plans cycle/interval
      setIsChangingInterval(false);
    }
    if (redirectTo) {
      gotoPrevPage();
    }
    dispatch({ type: 'HANDLE_PLANS_DIALOG', params: false });
  };

  useEffect(() => {
    if (planSearch) {
      if (planSearch.coupon) {
        // if there is any coupon
        setCoupon(planSearch.coupon);
      }
      // when the user is navigated from the main pricing page to dashboard
      // params stored in dashboard page state
      if (Object.keys(planSearch).length >= 3) {
        planSearch.users && setPersons(Number(planSearch.users));
        planSearch.billed === 'yearly'
          ? setYearlyPlane(true)
          : setYearlyPlane(false);
        // now we want to initiate chargebee modal like as we click on start now button

        const targetCard = pricingPlans.filter(
          (c) => c.name.toLowerCase() === planSearch.plan
        )[0];
        if (!selectedPlanId && targetCard) {
          // avoid refreshing of chargebee modal
          // clearPlanSearch();
          dispatch({ type: 'CLEAR_PLAN_SEARCH' });
          openCheckoutFunc(targetCard);
        }
      }
    }
  });

  useEffect(() => {
    if (plansErrorMessage) {
      NotificationManager.error(plansErrorMessage, '', 5000);
      dispatch({ type: 'EMPTY_PLANS_MESSAGE' });
    }
    if (plansSuccessMessage) {
      NotificationManager.success(plansSuccessMessage, '', 5000);
      dispatch({ type: 'EMPTY_PLANS_MESSAGE' });
    }
  }, [plansErrorMessage, plansSuccessMessage]);

  const closeDialog = () => {
    if (user.subscription) {
      dispatch({ type: 'HANDLE_PLANS_DIALOG', params: false });
    }
  };

  return (
    <Dialog
      open={isPlansDialogOpened || !user.subscription}
      modal={false}
      className="at-cs-modal-popover isolate m-auto my-0 w-[750px] rounded-none"
    >
      <Dialog.Portal className="m-auto my-0 w-[750px] rounded-none">
        <Dialog.Overlay className="" />
        <Dialog.Content
          className="m-auto my-0 h-[100vh] max-w-[100%] overflow-y-auto rounded-none sm:rounded-none"
          showClose={false}
        >
          {upgradePlansLoading ? (
            <div className={classes.stagesLoader}>
              <Lottie
                options={{
                  animationData: animationData,
                }}
                height={150}
                width={150}
              />
            </div>
          ) : (
            <div className={classes.mainDiv}>
              <div className={classes.firstDiv}>
                <div className={classes.closeIconDiv}>
                  {user.subscription ? (
                    <FiX
                      onClick={closePlansDialog}
                      className={classes.closeIcon}
                    />
                  ) : (
                    <span className={classes.closeIcon}></span>
                  )}
                </div>
                <div className={classes.topText}>
                  <h1 className={classes.heading}>
                    {title !== undefined ? title : defaultTitle}
                  </h1>
                  <p className={classes.dialogTitle}>
                    {description !== undefined
                      ? description
                      : defaultDescription}
                  </p>
                </div>
                <div className={classes.seatsContainer}>
                  <div className={classes.sliderSwitch}>
                    <PrettoSlider
                      onChange={sliderChange}
                      aria-label="pretto slider"
                      value={persons - 1}
                      step={1}
                      min={0}
                      max={maxSliderValue}
                      ThumbComponent={CustomThumb}
                    />
                    <div className={classes.dragSliderInfo}>
                      Drag the slider to add seats
                    </div>
                    <div className={classes.statusMenuItem}>
                      <>
                        <IosSwitchLarge
                          onChange={(e) => {
                            switchChange(e);
                          }}
                          isYearlyPlan={yearlyPlan}
                        />
                      </>
                    </div>
                  </div>
                  {isMoreSeatsVisible && (
                    <div
                      className={
                        'animate-blurred-fade-in max-w-md rounded-xl bg-white p-6 shadow-lg'
                      }
                    >
                      <h2 style={{ margin: '10px 0px' }}>
                        {maxSliderValue + '+'} Team Seats
                      </h2>
                      <p className={classes.moreSeatsdesc}>
                        We will learn about your processes and show you exactly
                        how Atarim can drop your project and care plan time by
                        more than half!
                      </p>
                      <div className={classes.bookCallcontainer}>
                        <Button
                          onClick={() =>
                            window.open('https://getgist.me/vito/schedule')
                          }
                          className={classes.bookCall}
                        >
                          Book a Call
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="mt-4 grid w-full max-w-[1400px] grow gap-4 pb-2 md:grid-cols-3">
                  {pricingPlans
                    .filter((eachPlan) => eachPlan.name !== 'Enterprise')
                    .map((card, index) => {
                      return (
                        <div
                          key={index}
                          className="flex w-full flex-col justify-start rounded-xl bg-white p-6 shadow-md"
                        >
                          <div style={{ minHeight: 230 }}>
                            <div className={classes.cardUpper}>
                              <h2 className={classes.cardName}>
                                {card?.display_name}
                              </h2>
                              <div>
                                {featureAvailable &&
                                  featureAvailable[index] && (
                                    <Typography
                                      className={classes.featureAvailable}
                                    >
                                      Feature Available
                                    </Typography>
                                  )}
                                {card?.plans[yearlyPlan ? 0 : 1]
                                  ?.current_plan && (
                                  <Typography className={classes.currentPlan}>
                                    {user.subscription
                                      ? 'Current Plan'
                                      : 'Expired Plan'}
                                  </Typography>
                                )}
                              </div>
                            </div>
                            <font color="#363D4D" size="10">
                              <b>${calculatePrice(card)}</b>
                            </font>
                            {yearlyPlan ? '/year' : '/month'}
                            <br />
                            <p className={classes.perSeat}>
                              {yearlyPlan
                                ? `+ $${card.plans[0]?.extra_seat_info_yearly}/year per extra seat`
                                : `+ $${card.plans[1]?.extra_seat_info_monthly}/month per extra seat`}
                            </p>
                            <br />
                            <b>
                              {card?.plans[yearlyPlan ? 0 : 1]?.extra_seat_info}
                            </b>
                            <p className={classes.cardDescription}>
                              {card?.description}
                            </p>
                          </div>
                          <div>
                            {anchorEl === card?.id && addedUsers > persons && (
                              <div
                                onMouseEnter={() => handleOpen(card?.id)}
                                onMouseLeave={handleClose}
                                className={classes.popoverDiv}
                                onClick={() => {
                                  navigate(`/users?userId=${user.id}`);
                                  dispatch({
                                    type: 'HANDLE_PLANS_DIALOG',
                                    params: false,
                                  });
                                }}
                              >
                                <Typography sx={{ p: 2 }}>
                                  You cannot buy this plan because you already
                                  have
                                  <b> {addedUsers} </b>
                                  users. <br /> If you want to mange users Click
                                  here.
                                </Typography>
                              </div>
                            )}
                            <Button
                              onMouseEnter={() => handleOpen(card?.id)}
                              onMouseLeave={handleClose}
                              style={{
                                backgroundColor: disabledButton(card)
                                  ? '#D5E2F3'
                                  : '#6D5DF3',
                              }}
                              variant="contained"
                              className={classes.startNowButton}
                              onClick={() => {
                                if (!disabledButton(card)) {
                                  openCheckoutFunc(card);
                                }
                              }}
                            >
                              {getButtonText(card)}
                            </Button>
                          </div>
                          <div>
                            <span className={classes.greyText}>
                              <h3>
                                Everything on{' '}
                                <span className={classes.planEverything}>
                                  {featureTitle[index]}
                                </span>{' '}
                                +
                              </h3>
                              {card?.features?.checked?.map((each) => (
                                <p className={classes.cardInfo}>
                                  <span>
                                    <CheckedIcon />
                                  </span>
                                  <span>{each}</span>
                                </p>
                              ))}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  <div className="flex flex-col justify-start rounded-xl bg-white p-6 shadow-md">
                    <div className={classes.copyWrapper}>
                      <div className={classes.cardUpper}>
                        <h2 className={classes.cardName}>Enterprise</h2>
                        {featureAvailable && featureAvailable[3] && (
                          <Typography className={classes.featureAvailable}>
                            Feature Available
                          </Typography>
                        )}
                      </div>
                      <font color="#363D4D" size="10">
                        <b>Custom</b>
                      </font>
                      <br />
                      <p className={classes.perSeat}>
                        For large orgs & Hosting providers
                      </p>
                      <br />
                      <b></b>
                      <p className={classes.cardDescription}>
                        {enterpriseFeature?.description}
                      </p>
                    </div>
                    <div>
                      <Button
                        style={{
                          backgroundColor: disabledButton('card')
                            ? '#D5E2F3'
                            : '#6D5DF3',
                        }}
                        variant="contained"
                        className={classes.startNowButton}
                        onClick={() =>
                          window.open('https://getgist.me/vito/schedule')
                        }
                      >
                        Book a call
                      </Button>
                    </div>
                    <div>
                      <span className={classes.greyText}>
                        <h3>Everything on Business +</h3>
                        {enterpriseFeature?.features?.checked.map((each, i) => (
                          <p key={i} className={classes.cardInfo}>
                            <span>
                              <CheckedIcon />
                            </span>
                            <span>{each}</span>
                          </p>
                        ))}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="pt-4 md:p-4 md:pt-8">
                  <h2 className="mx-auto mb-8 w-full text-center text-4xl font-semibold leading-[1.1em] md:text-[54px] lg:max-w-[900px]">
                    <span className={classes.highlightPlan}>
                      Speed up creative projects
                    </span>{' '}
                    with fast feedback and a better workflow.
                  </h2>
                  <table className="md:max-w[1400px] block max-w-[calc(100vw-30px)] overflow-x-scroll">
                    <thead className={classes.tableHead}>
                      <tr>
                        <th
                          dataSort="0"
                          className={classes.headTdMain}
                          scope="col"
                        >
                          <span className={classes.headContent}>
                            Feature Comparison
                          </span>
                        </th>
                        <th dataSort="0" className={classes.headTd} scope="col">
                          <span className={classes.headContent}>Free</span>
                        </th>
                        {/* <th dataSort="0" className={classes.headTd} scope="col">
                                                <span className={classes.headContent}>Starter</span>
                                            </th> */}
                        <th dataSort="0" className={classes.headTd} scope="col">
                          <span className={classes.headContent}>Pro</span>
                        </th>
                        <th dataSort="0" className={classes.headTd} scope="col">
                          <span className={classes.headContent}>Business</span>
                        </th>
                        <th dataSort="0" className={classes.headTd} scope="col">
                          <span className={classes.headContent}>
                            Enterprise
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((each, i) => (
                        <tr className={i % 2 === 0 ? classes.dataRow : ''}>
                          {each.data.map(
                            (item, key) =>
                              key !== 2 && (
                                <td className={classes.dataItem}>
                                  <span className={classes.dataContent}>
                                    {item}
                                  </span>
                                </td>
                              )
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {isPriceUpdated && (
                  <div className={classes.priceUpdatedInfo}>
                    <Typography sx={{ p: 2 }}>
                      You will be charged according to your previous
                      subscription until you purchase a new pricing plan.
                    </Typography>
                    <FiX
                      onClick={() => setIsPriceUpdated(false)}
                      className={classes.closeIconPopup}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog>

    // <Dialog
    //   fullScreen={fullScreen}
    //   open={isPlansDialogOpened || !user.subscription}
    //   disableEnforceFocus={true}
    //   onClose={closeDialog}
    //   scroll="paper"
    //   PaperProps={{
    //     className: classes.dialogClass,
    //   }}
    //   classes={{
    //     scrollPaper: classes.scrollPaper,
    //   }}
    //   className="at-cs-upgrade-modal-overlay"
    // >
    //   {upgradePlansLoading ? (
    //     <div className={classes.stagesLoader}>
    //       <Lottie
    //         options={{
    //           animationData: animationData,
    //         }}
    //         height={150}
    //         width={150}
    //       />
    //     </div>
    //   ) : (
    //     <div className={classes.mainDiv}>
    //       <div className={classes.firstDiv}>
    //         <div className={classes.closeIconDiv}>
    //           {user.subscription ? (
    //             <FiX onClick={closePlansDialog} className={classes.closeIcon} />
    //           ) : (
    //             <span className={classes.closeIcon}></span>
    //           )}
    //         </div>
    //         <div className={classes.topText}>
    //           <h1 className={classes.heading}>
    //             {title !== undefined ? title : defaultTitle}
    //           </h1>
    //           <p className={classes.dialogTitle}>
    //             {description !== undefined ? description : defaultDescription}
    //           </p>
    //         </div>
    //         <div className={classes.seatsContainer}>
    //           <div className={classes.sliderSwitch}>
    //             <PrettoSlider
    //               onChange={sliderChange}
    //               aria-label="pretto slider"
    //               value={persons - 1}
    //               step={1}
    //               min={0}
    //               max={maxSliderValue}
    //               ThumbComponent={CustomThumb}
    //             />
    //             <div className={classes.dragSliderInfo}>
    //               Drag the slider to add seats
    //             </div>
    //             <div className={classes.statusMenuItem}>
    //               <>
    //                 <IosSwitchLarge
    //                   onChange={(e) => {
    //                     switchChange(e);
    //                   }}
    //                   isYearlyPlan={yearlyPlan}
    //                 />
    //               </>
    //             </div>
    //           </div>
    //           {isMoreSeatsVisible && (
    //             <div
    //               className={
    //                 'max-w-md animate-blurred-fade-in rounded-xl bg-white p-6 shadow-lg'
    //               }
    //             >
    //               <h2 style={{ margin: '10px 0px' }}>
    //                 {maxSliderValue + '+'} Team Seats
    //               </h2>
    //               <p className={classes.moreSeatsdesc}>
    //                 We will learn about your processes and show you exactly how
    //                 Atarim can drop your project and care plan time by more than
    //                 half!
    //               </p>
    //               <div className={classes.bookCallcontainer}>
    //                 <Button
    //                   onClick={() =>
    //                     window.open('https://getgist.me/vito/schedule')
    //                   }
    //                   className={classes.bookCall}
    //                 >
    //                   Book a Call
    //                 </Button>
    //               </div>
    //             </div>
    //           )}
    //         </div>
    //         <div className={classes.cards}>
    //           {pricingPlans
    //             .filter((eachPlan) => eachPlan.name !== 'Enterprise')
    //             .map((card, index) => {
    //               return (
    //                 <div key={index} className={classes.card}>
    //                   <div style={{ minHeight: 230 }}>
    //                     <div className={classes.cardUpper}>
    //                       <h2 className={classes.cardName}>
    //                         {card?.display_name}
    //                       </h2>
    //                       <div>
    //                         {featureAvailable && featureAvailable[index] && (
    //                           <Typography className={classes.featureAvailable}>
    //                             Feature Available
    //                           </Typography>
    //                         )}
    //                         {card?.plans[yearlyPlan ? 0 : 1]?.current_plan && (
    //                           <Typography className={classes.currentPlan}>
    //                             {user.subscription
    //                               ? 'Current Plan'
    //                               : 'Expired Plan'}
    //                           </Typography>
    //                         )}
    //                       </div>
    //                     </div>
    //                     <font color="#363D4D" size="10">
    //                       <b>${calculatePrice(card)}</b>
    //                     </font>
    //                     {yearlyPlan ? '/year' : '/month'}
    //                     <br />
    //                     <p className={classes.perSeat}>
    //                       {yearlyPlan
    //                         ? `+ $${card.plans[0]?.extra_seat_info_yearly}/year per extra seat`
    //                         : `+ $${card.plans[1]?.extra_seat_info_monthly}/month per extra seat`}
    //                     </p>
    //                     <br />
    //                     <b>
    //                       {card?.plans[yearlyPlan ? 0 : 1]?.extra_seat_info}
    //                     </b>
    //                     <p className={classes.cardDescription}>
    //                       {card?.description}
    //                     </p>
    //                   </div>
    //                   <div>
    //                     {anchorEl === card?.id && addedUsers > persons && (
    //                       <div
    //                         onMouseEnter={() => handleOpen(card?.id)}
    //                         onMouseLeave={handleClose}
    //                         className={classes.popoverDiv}
    //                         onClick={() => {
    //                           navigate(`/users?userId=${user.id}`);
    //                           dispatch({
    //                             type: 'HANDLE_PLANS_DIALOG',
    //                             params: false,
    //                           });
    //                         }}
    //                       >
    //                         <Typography sx={{ p: 2 }}>
    //                           You cannot buy this plan because you already have
    //                           <b> {addedUsers} </b>
    //                           users. <br /> If you want to mange users Click
    //                           here.
    //                         </Typography>
    //                       </div>
    //                     )}
    //                     <Button
    //                       onMouseEnter={() => handleOpen(card?.id)}
    //                       onMouseLeave={handleClose}
    //                       style={{
    //                         backgroundColor: disabledButton(card)
    //                           ? '#D5E2F3'
    //                           : '#6D5DF3',
    //                       }}
    //                       variant="contained"
    //                       className={classes.startNowButton}
    //                       onClick={() => {
    //                         if (!disabledButton(card)) {
    //                           openCheckoutFunc(card);
    //                         }
    //                       }}
    //                     >
    //                       {getButtonText(card)}
    //                     </Button>
    //                   </div>
    //                   <div>
    //                     <span className={classes.greyText}>
    //                       <h3>
    //                         Everything on{' '}
    //                         <span className={classes.planEverything}>
    //                           {featureTitle[index]}
    //                         </span>{' '}
    //                         +
    //                       </h3>
    //                       {card?.features?.checked?.map((each) => (
    //                         <p className={classes.cardInfo}>
    //                           <span>
    //                             <CheckedIcon />
    //                           </span>
    //                           <span>{each}</span>
    //                         </p>
    //                       ))}
    //                     </span>
    //                   </div>
    //                 </div>
    //               );
    //             })}
    //           <div className={classes.card}>
    //             <div className={classes.copyWrapper}>
    //               <div className={classes.cardUpper}>
    //                 <h2 className={classes.cardName}>Enterprise</h2>
    //                 {featureAvailable && featureAvailable[3] && (
    //                   <Typography className={classes.featureAvailable}>
    //                     Feature Available
    //                   </Typography>
    //                 )}
    //               </div>
    //               <font color="#363D4D" size="10">
    //                 <b>Custom</b>
    //               </font>
    //               <br />
    //               <p className={classes.perSeat}>
    //                 For large orgs & Hosting providers
    //               </p>
    //               <br />
    //               <b></b>
    //               <p className={classes.cardDescription}>
    //                 {enterpriseFeature?.description}
    //               </p>
    //             </div>
    //             <div>
    //               <Button
    //                 style={{
    //                   backgroundColor: disabledButton('card')
    //                     ? '#D5E2F3'
    //                     : '#6D5DF3',
    //                 }}
    //                 variant="contained"
    //                 className={classes.startNowButton}
    //                 onClick={() =>
    //                   window.open('https://getgist.me/vito/schedule')
    //                 }
    //               >
    //                 Book a call
    //               </Button>
    //             </div>
    //             <div>
    //               <span className={classes.greyText}>
    //                 <h3>Everything on Business +</h3>
    //                 {enterpriseFeature?.features?.checked.map((each, i) => (
    //                   <p key={i} className={classes.cardInfo}>
    //                     <span>
    //                       <CheckedIcon />
    //                     </span>
    //                     <span>{each}</span>
    //                   </p>
    //                 ))}
    //               </span>
    //             </div>
    //           </div>
    //         </div>
    //         <div className={classes.planSection}>
    //           <h2 className={classes.planTableHeader}>
    //             <span className={classes.highlightPlan}>
    //               Speed up creative projects
    //             </span>{' '}
    //             with fast feedback and a better workflow.
    //           </h2>
    //           <table className={classes.table}>
    //             <thead className={classes.tableHead}>
    //               <tr>
    //                 <th dataSort="0" className={classes.headTdMain} scope="col">
    //                   <span className={classes.headContent}>
    //                     Feature Comparison
    //                   </span>
    //                 </th>
    //                 <th dataSort="0" className={classes.headTd} scope="col">
    //                   <span className={classes.headContent}>Free</span>
    //                 </th>
    //                 {/* <th dataSort="0" className={classes.headTd} scope="col">
    //                                             <span className={classes.headContent}>Starter</span>
    //                                         </th> */}
    //                 <th dataSort="0" className={classes.headTd} scope="col">
    //                   <span className={classes.headContent}>Pro</span>
    //                 </th>
    //                 <th dataSort="0" className={classes.headTd} scope="col">
    //                   <span className={classes.headContent}>Business</span>
    //                 </th>
    //                 <th dataSort="0" className={classes.headTd} scope="col">
    //                   <span className={classes.headContent}>Enterprise</span>
    //                 </th>
    //               </tr>
    //             </thead>
    //             <tbody>
    //               {tableData.map((each, i) => (
    //                 <tr className={i % 2 === 0 ? classes.dataRow : ''}>
    //                   {each.data.map(
    //                     (item, key) =>
    //                       key !== 2 && (
    //                         <td className={classes.dataItem}>
    //                           <span className={classes.dataContent}>
    //                             {item}
    //                           </span>
    //                         </td>
    //                       )
    //                   )}
    //                 </tr>
    //               ))}
    //             </tbody>
    //           </table>
    //         </div>
    //         {isPriceUpdated && (
    //           <div className={classes.priceUpdatedInfo}>
    //             <Typography sx={{ p: 2 }}>
    //               You will be charged according to your previous subscription
    //               until you purchase a new pricing plan.
    //             </Typography>
    //             <FiX
    //               onClick={() => setIsPriceUpdated(false)}
    //               className={classes.closeIconPopup}
    //             />
    //           </div>
    //         )}
    //       </div>
    //     </div>
    //   )}
    // </Dialog>
  );
};

export default PlanUpgrading;
