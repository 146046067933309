import MobileNav from '@src/components/navs/MobileNav/MobileNav';
import SiteNav from '@src/components/navs/SiteNav';
import { useAppDispatch, useAppSelector } from '@src/hooks/redux';
import {
  bulkUpdateFeatureFlags,
  FeatureFlags,
} from '@src/store/slices/featureflags';
import { SETTINGS_PAGE_MOUNTED } from 'actions/global_settings';
import { usePostHog } from 'posthog-js/react';
import { productFruits } from 'product-fruits';
import { Suspense, useEffect } from 'react';
import { NotificationContainer } from 'react-notifications';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  sendMessageToExtension,
  sendMessageToSiteControl,
} from 'utils/chromeExtension';
import { useResponsive } from 'utils/useMediaQuery';
import PageLoading from '../components/PageLoading';
import TrialHeaderBar from '../components/collaborate/TrialHeaderBar';
import PlanUpgrading from '../components/plans/PlansUpgrading';
import usePluginActivationRedirect from '../hooks/pluginRedirect';
import { useAuth } from '../hooks/useAuth';
import useExport from '../hooks/useExport';
import useWorkspaceChangeEffect from '../hooks/workspaceChange';
import ErrorBoundary from './ErrorBoundary';

export default function Layout() {
  const { userData, token } = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();
  const { isLoadingAuth } = useAuth();
  const location = useLocation();
  const { isMobile, isDesktop, isTablet, isLargeDesktop } = useResponsive();
  const { workspaceData } = useAppSelector((state) => state.workspace);
  const navigate = useNavigate();
  const posthogClient = usePostHog();
  // Feature flags
  dispatch(
    bulkUpdateFeatureFlags({
      [FeatureFlags.COLLABORATE_V2]: posthogClient.isFeatureEnabled(
        FeatureFlags.COLLABORATE_V2
      ),
    })
  );

  usePluginActivationRedirect();
  useWorkspaceChangeEffect();
  //TODO: Move this to inbox and board, once board is a functional component
  useExport();

  // TODO: this may be moved to a separate hook or removed
  useEffect(() => {
    if (!isLoadingAuth) {
      if (token && localStorage.getItem('token') !== 'guestToken') {
        dispatch({ type: SETTINGS_PAGE_MOUNTED });
      } else if (!token) {
        sendMessageToExtension({ type: 'logout' });
      }
    }
  }, [token, isLoadingAuth]);
  useEffect(() => {
    if (
      userData &&
      userData.trial?.status === 'inactive' &&
      !userData.trial?.company_name
    ) {
      if (location.pathname === '/collaborate') return;
      return navigate(`/get-started${location.search}`);
    }
  }, [userData]);

  // TODO: Clean up this effect into hooks
  useEffect(() => {
    if (userData) {
      window.gsc('params', {
        plan: !userData?.plan?.user_permissions && 'false',
      });

      const userInfo = {
        username: userData.email,
        email: userData.email,
        firstname: userData.first_name,
        lastname: userData.last_name,
        role: userData.role,
        props: { plan: userData.plan },
      };

      productFruits.init('02hLgOXsohviuqyd', 'en', userInfo, {
        disableLocationChangeDetection: true,
        customNavigation: {
          use: true,
          navigate: (url: string) => {
            if (url.startsWith('https')) {
              window.location.href = url;
            } else {
              navigate(url);
            }
          },
          onGet() {
            return window.location.href;
          },
        },
      });

      posthogClient.identify(userData.id + '_' + userData.email, {
        email: userData.email,
        plan: userData?.plan?.title,
        username: userData.username,
        environment: import.meta.env.VITE_APP_BASE_URL,
      });
    } else {
      posthogClient.reset();
    }
    sendMessageToSiteControl();
  }, [userData, workspaceData, dispatch, posthogClient]);

  return (
    <ErrorBoundary>
      <NotificationContainer />
      {userData ? (
        <>
          {location.pathname !== '/fetching' &&
            location.pathname !== '/get-started' &&
            (isTablet || isDesktop || isLargeDesktop) && <SiteNav />}
          <div className="flex h-screen w-full flex-col overflow-y-auto">
            {location.pathname !== '/collaborate' && <TrialHeaderBar />}
            <Suspense fallback={<PageLoading />}>
              <Outlet />
              {userData && !userData.subscription && (
                <PlanUpgrading title={'Renew Subscription'} />
              )}
            </Suspense>
            {isMobile && <MobileNav />}
          </div>
        </>
      ) : (
        <div className="content">
          <Suspense fallback={<PageLoading />}>
            <Outlet />
          </Suspense>
        </div>
      )}
    </ErrorBoundary>
  );
}

