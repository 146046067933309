import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { taskApi } from '../api/endpoints/task';
import type {
  AllTaskData,
  NotifyWpFeedbackUser,
  Project,
  TaskAttributes,
  TaskData,
  TaskPriority,
  TaskStatus,
  TaskTag,
} from '../types/task';

export type ComposeState =
  | 'compose_email'
  | 'reply_email'
  | 'idle'
  | 'compose_task'
  | 'reply_task';

export type InboxMobileTab = 'tasks' | 'inbox' | 'details';

export type BoardsMobileTab = 'board' | 'comment' | 'details';

export type CustomTaskViewState = {
  task_number: boolean;
  created_by: boolean;
  created_role: boolean;
  task_title: boolean;
  screenshot: boolean;
  date: boolean;
  page_name: boolean;
  project_name: boolean;
  page_url: boolean;
  website_url: boolean;
  pin: boolean;
  type: boolean;
  tags: boolean;
  status: boolean;
  priority: boolean;
  assignee: boolean;
};

type ComposeTaskState = {
  project?: Project;
  notifiedUsers?: NotifyWpFeedbackUser[];
  status?: TaskStatus | null;
  priority?: TaskPriority | null;
  tags?: TaskTag[];
  assignedUser?: NotifyWpFeedbackUser | null;
};

type TaskState = {
  selectedProject: Project | null;
  bulkTaskIds: string[];
  inboxMobileTab: InboxMobileTab;
  boardsMobileTab: BoardsMobileTab;
  columnOffsets: Record<string, number> | null;
  taskListOffset: number;
  siteListOffset: number;
  totalRecords: number;
  totalColumnRecords: number;
  selectedTask: TaskData | AllTaskData | null;
  attributes: TaskAttributes | null;
  isTasksLoading: boolean;
  isAttributeLoading: boolean;
  isTaskListFiltered: boolean;
  isBulkSelecting: boolean;
  composeState: ComposeState;
  isDetailsDrawerOpen: boolean;
  customTaskViewState: CustomTaskViewState;
  composeTaskState: ComposeTaskState;
};

const initialState: TaskState = {
  selectedProject: null,
  bulkTaskIds: [],
  inboxMobileTab: 'tasks',
  boardsMobileTab: 'board',
  columnOffsets: null,
  taskListOffset: 0,
  siteListOffset: 0,
  totalRecords: 0,
  totalColumnRecords: 0,
  selectedTask: null,
  attributes: null,
  isTasksLoading: false,
  isAttributeLoading: false,
  isTaskListFiltered: false,
  isBulkSelecting: false,
  composeState: 'idle',
  composeTaskState: {
    project: undefined,
    notifiedUsers: [],
    status: 'open',
    priority: 'low',
    tags: [],
    assignedUser: null,
  },
  isDetailsDrawerOpen: false,
  customTaskViewState: {
    task_number: true,
    task_title: true,
    screenshot: true,
    created_by: true,
    created_role: true,
    date: true,
    pin: true,
    type: true,
    tags: true,
    status: true,
    priority: true,
    page_name: false,
    project_name: false,
    website_url: false,
    page_url: false,
    assignee: true,
  },
};

const taskSlice = createSlice({
  name: 'tasks_new',
  initialState,
  reducers: {
    setComposeState: (state, action: PayloadAction<ComposeState>) => {
      state.composeState = action.payload;
      if (
        action.payload === 'compose_email' ||
        action.payload === 'compose_task'
      ) {
        state.selectedTask = null;
      }
    },
    resetComposeState: (state) => {
      state.composeState = 'idle';
    },
    setDetailsDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.isDetailsDrawerOpen = action.payload;
    },
    setInboxMobileTab: (state, action: PayloadAction<InboxMobileTab>) => {
      state.inboxMobileTab = action.payload;
    },
    setBoardsMobileTab: (state, action: PayloadAction<BoardsMobileTab>) => {
      state.boardsMobileTab = action.payload;
    },
    setSelectedProject: (state, action: PayloadAction<Project | null>) => {
      state.selectedProject = action.payload;
    },
    resetSelectedProject: (state) => {
      state.selectedProject = null;
    },
    setTaskListIsFiltered: (state, action: PayloadAction<boolean>) => {
      state.isTaskListFiltered = action.payload;
    },
    setTaskListOffset: (state, action: PayloadAction<number>) => {
      state.taskListOffset = action.payload;
    },
    setSiteListOffset: (state, action: PayloadAction<number>) => {
      state.siteListOffset = action.payload;
    },
    resetTaskListOffset: (state) => {
      state.taskListOffset = 0;
    },
    resetSiteListOffset: (state) => {
      state.siteListOffset = 0;
    },
    updateColumnOffsets: (
      state,
      action: PayloadAction<{
        column: string;
        newOffset: number;
      }>
    ) => {
      if (state.columnOffsets) {
        state.columnOffsets[action.payload.column] = action.payload.newOffset;
      }
    },
    setColumnOffsets: (
      state,
      action: PayloadAction<Record<string, number>>
    ) => {
      state.columnOffsets = action.payload;
    },
    resetColumnOffsets: (state) => {
      if (state.columnOffsets) {
        // set all column offsets to 0
        state.columnOffsets = Object.fromEntries(
          Object.keys(state.columnOffsets).map((col) => [col, 0])
        );
      }
    },
    setCustomTaskViewState: (
      state,
      action: PayloadAction<{
        key: keyof CustomTaskViewState;
        value: boolean;
      }>
    ) => {
      state.customTaskViewState[action.payload.key] = action.payload.value;
    },
    resetCustomTaskViewState: (state) => {
      state.customTaskViewState = initialState.customTaskViewState;
    },
    setSelectedTask: (
      state,
      action: PayloadAction<TaskData | AllTaskData | null>
    ) => {
      state.selectedTask = action.payload;
      state.composeState = 'idle';
    },
    setAttributes: (state, action: PayloadAction<TaskAttributes | null>) => {
      state.attributes = action.payload;
    },
    setIsTasksLoading: (state, action: PayloadAction<boolean>) => {
      state.isTasksLoading = action.payload;
    },
    setBulkTaskIds: (state, action: PayloadAction<string[]>) => {
      state.bulkTaskIds = action.payload;
      state.isBulkSelecting = state.bulkTaskIds.length > 0;
    },
    addBulkTaskId: (state, action: PayloadAction<string>) => {
      if (!state.bulkTaskIds.includes(action.payload)) {
        state.bulkTaskIds.push(action.payload);
      }
      state.isBulkSelecting = state.bulkTaskIds.length > 0;
    },
    removeBulkTaskId: (state, action: PayloadAction<string>) => {
      state.bulkTaskIds = state.bulkTaskIds.filter((id) => id !== action.payload);
      state.isBulkSelecting = state.bulkTaskIds.length > 0;
    },
    setBulkSelecting: (state, action: PayloadAction<boolean>) => {
      if (action.payload === false) {
        state.bulkTaskIds = [];
      }
      state.isBulkSelecting = action.payload;
    },
    setComposeTaskState: (
      state,
      action: PayloadAction<Partial<ComposeTaskState>>
    ) => {
      state.composeTaskState = action.payload;
    },
    resetComposeTaskState: (state) => {
      state.composeTaskState = initialState.composeTaskState;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      taskApi.endpoints.fetchTaskAttributes.matchFulfilled,
      (state, { payload }: PayloadAction<TaskAttributes>) => {
        state.attributes = payload;
        state.isAttributeLoading = false;
      }
    );
    builder.addMatcher(
      taskApi.endpoints.fetchTaskAttributes.matchPending,
      (state) => {
        state.isAttributeLoading = true;
      }
    );
    builder.addMatcher(
      taskApi.endpoints.fetchTaskAttributes.matchRejected,
      (state) => {
        state.isAttributeLoading = false;
      }
    );
    builder.addMatcher(
      taskApi.endpoints.fetchTaskList.matchFulfilled,
      (state) => {
        state.isTasksLoading = false;
      }
    );
    builder.addMatcher(
      taskApi.endpoints.fetchTaskList.matchPending,
      (state) => {
        state.isTasksLoading = true;
      }
    );
    builder.addMatcher(
      taskApi.endpoints.fetchTaskList.matchRejected,
      (state) => {
        state.isTasksLoading = false;
      }
    );
    builder.addMatcher(
      taskApi.endpoints.fetchAllTasks.matchFulfilled,
      (state) => {
        state.isTasksLoading = false;
      }
    );
    builder.addMatcher(
      taskApi.endpoints.fetchAllTasks.matchPending,
      (state) => {
        state.isTasksLoading = true;
      }
    );
    builder.addMatcher(
      taskApi.endpoints.fetchAllTasks.matchRejected,
      (state) => {
        state.isTasksLoading = false;
      }
    );
  },
});

export const {
  setComposeState,
  setInboxMobileTab,
  setBoardsMobileTab,
  setSelectedProject,
  resetSelectedProject,
  setDetailsDrawerOpen,
  setTaskListIsFiltered,
  setSelectedTask,
  setAttributes,
  setIsTasksLoading,
  setTaskListOffset,
  setSiteListOffset,
  resetTaskListOffset,
  resetSiteListOffset,
  setBulkTaskIds,
  setBulkSelecting,
  addBulkTaskId,
  removeBulkTaskId,
  setColumnOffsets,
  updateColumnOffsets,
  resetColumnOffsets,
  setCustomTaskViewState,
  setComposeTaskState,
  resetComposeTaskState,
  resetCustomTaskViewState,
} = taskSlice.actions;

export default taskSlice.reducer;
