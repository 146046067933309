import { cva } from 'class-variance-authority';
import { motion } from 'framer-motion';
import { cn } from 'utils/cn';
const progressVariants = cva('h-full w-full rounded-full', {
  variants: {
    variant: {
      primary: 'bg-blue-500',
      secondary: 'bg-tertiary',
      success: 'bg-primary',
    },
    size: {
      sm: 'h-2.5',
      md: 'h-4',
    },
  },
  defaultVariants: {
    size: 'sm',
    variant: 'primary',
  },
});

function Progress({ className, variant, size, progress }) {
  return (
    <motion.div
      className="relative w-full overflow-hidden rounded-full border-transparent bg-[#EFF2F6]"
      style={{
        // Fix overflow clipping in Safari
        // https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
        transform: 'translateZ(0)',
      }}
      value={progress}
    >
      <motion.div
        animate={{ x: `calc(-100% + ${progress}%)` }}
        transition={{ duration: 2, type: 'tween', ease: [0.65, 0, 0.35, 1] }}
        className={cn(progressVariants({ variant, size, className }))}
        style={{ x: '-100%' }}
      ></motion.div>
    </motion.div>
  );
}

export { Progress, progressVariants };
