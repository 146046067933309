export function convertToRgb(color) {
  if (color.startsWith('#')) {
    let hex = color.replace(/^#/, '');
    if (hex.length === 3) {
      hex = hex
        .split('')
        .map((hex) => hex + hex)
        .join('');
    }
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  } else if (color.startsWith('rgba')) {
    const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
    return rgba.slice(0, 3).map(Number);
  } else if (color.startsWith('hsla') || color.startsWith('hsl')) {
    const hsla = color.replace(/^hsla?\(|\s+|\)$/g, '').split(',');
    let h = parseFloat(hsla[0]);
    let s = parseFloat(hsla[1]) / 100;
    let l = parseFloat(hsla[2]) / 100;

    if (s === 0) {
      l = Math.round(l * 255);
      return [l, l, l];
    } else {
      const hueToRgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      const r = hueToRgb(p, q, h + 1 / 3);
      const g = hueToRgb(p, q, h);
      const b = hueToRgb(p, q, h - 1 / 3);
      return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    }
  }
  return [0, 0, 0];
}

export function getContrastingColor(colour) {
  var rgb = convertToRgb(colour);
  var luminance = 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];
  return luminance > 128 ? '#000000' : '#FFFFFF';
}

// export function _stringAsciiPRNG(value, m) {
//   const charCodes = [...value].map((letter) => letter.charCodeAt(0));
//   const len = charCodes.length;

//   const a = (len % (m - 1)) + 1;
//   const c = charCodes.reduce((current, next) => current + next) % m;

//   let random = charCodes[0] % m;
//   for (let i = 0; i < len; i++) random = (a * random + c) % m;

//   return random;
// }

// export function _numberPRNG(seed, m, a = 42069, c = 9001) {
//   let random = seed;
//   random = (a * random + c) % m;

//   return random;
// }

// Improved string-based PRNG
export function _stringAsciiPRNG(value, m) {
  const charCodes = [...value].map((letter) => letter.charCodeAt(0));
  const len = charCodes.length;

  // Use a larger prime number for 'a' to increase period
  const a = 16807; // A prime number often used in PRNGs
  // Use a more complex calculation for 'c'
  const c =
    charCodes.reduce(
      (current, next, index) => current + next * (index + 1),
      0
    ) % m;

  let random = charCodes.reduce((sum, code) => sum * 31 + code, 0) % m;
  for (let i = 0; i < len; i++) {
    random = (a * random + c) % m;
    // Additional mixing step
    random = (random ^ (random >> 15)) % m;
  }

  return random;
}

// Improved number-based PRNG
export function _numberPRNG(seed, m) {
  // Use constants from the widely-used "Numerical Recipes" linear congruential generator
  const a = 1664525;
  const c = 1013904223;

  let random = seed;
  // Perform multiple iterations to improve distribution
  for (let i = 0; i < 3; i++) {
    random = (a * random + c) % 0x100000000; // Use 2^32 as modulus for better distribution
  }

  // Map the result to the desired range
  return random % m;
}

// Helper function to generate a more diverse hash for strings
export function stringToHash(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32-bit integer
  }
  return Math.abs(hash);
}

export const defaultColors = [
  '#6D5DF3',
  '#3ED696',
  '#FFA532',
  '#FF5A48',
  '#279AF1',
  '#282B5A',
];

export function getRandomColor(value, colors = defaultColors) {
  if (!value) return 'transparent';

  let seed;
  if (typeof value === 'string') {
    seed = stringToHash(value);
  } else if (typeof value === 'number') {
    seed = value;
  } else {
    return colors[0]; // Default to first color if value is neither string nor number
  }

  const index = _numberPRNG(seed, colors.length);
  return colors[index];
}
